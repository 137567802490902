import { render, staticRenderFns } from "./CandidateProfileReview.vue?vue&type=template&id=2b489341&scoped=true&"
import script from "./CandidateProfileReview.vue?vue&type=script&lang=js&"
export * from "./CandidateProfileReview.vue?vue&type=script&lang=js&"
import style0 from "./CandidateProfileReview.vue?vue&type=style&index=0&id=2b489341&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b489341",
  null
  
)

export default component.exports